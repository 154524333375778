import { useBootstrap } from '@admin/hooks';
import { find, subtract, result, trim } from 'lodash-es';

export const sortCompare = (field, isDate) => {
  return {
    compare: (a, b) => {
      const prev = result(a, field);
      const next = result(b, field);
      if (isDate) return subtract(new Date(prev).getTime(), new Date(next).getTime());
      else {
        let type = ![null, undefined].includes(prev) ? typeof prev : typeof next;
        if (type === 'string') return stringCompare(trim(prev), trim(next));
        else if (type === 'number') return subtract(prev, next);
        else return 0;
      }
    },
  };
};

export const selectionCompare = (name, field) => {
  return (a, b) => {
    const obj = useBootstrap()?.[name];
    const prevText = obj?.[result(a, field)]?.name ?? '-';
    const nextText = obj?.[result(b, field)]?.name ?? '-';
    return stringCompare(prevText, nextText);
  };
};

export const enumCompare = (name, field) => {
  return (a, b) => {
    const { ENUMS } = useBootstrap();
    const prevText = find(ENUMS.value?.[name], { name: result(a, field) })?.label ?? '-';
    const nextText = find(ENUMS.value?.[name], { name: result(b, field) })?.label ?? '-';
    return stringCompare(prevText, nextText);
  };
};

export const booleanCompare = field => {
  return (a, b) => {
    const prev = result(a, field) ? 0 : 1;
    const next = result(b, field) ? 0 : 1;
    return subtract(prev, next);
  };
};

const stringCompare = (a, b) => {
  let strA = trim(a ?? '');
  let strB = trim(b ?? '');
  const length = strA.length > strB.length ? strA.length : strB.length;
  for (let i = 0; i < length; i++) {
    let aCharCode = getCharCode(strA, i);
    let bCharCode = getCharCode(strB, i);
    if (aCharCode !== bCharCode) return aCharCode - bCharCode;
  }
  return 0;
};

const getCharCode = (str, index) => {
  let charCode = [undefined, null, NaN].includes(str) ? 0 : str.charCodeAt(index);
  return isNaN(charCode) ? 0 : charCode;
};
