import { h, toRaw } from 'vue';
import BigNumber from 'bignumber.js';
import { ENUM_COLOR } from '@/constant';
import { useBootstrap, useLanguage } from '@admin/hooks';
import NShortText from '@/components/common/NShortText.vue';
import NShortNumber from '@/components/common/NShortNumber.vue';
import { find, isArray, result, upperFirst, last } from 'lodash-es';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons-vue';
import { booleanCompare, enumCompare, selectionCompare, sortCompare } from '@admin/utils';

export const SORT_TYPE = { LOCAL: 'local', SERVER: 'server' };

export class Column {
  constructor(baseOptions = {}) {
    const {
      align = 'center',
      sort, // 'local' or 'server'，默认 undefined（不开启列排序）
      sorter, // sort === 'server' 时必须传 sorter，默认 undefined
      ellipsis = true,
      showSorterTooltip = false,
      customRender = ({ text }) => ([undefined, null, NaN, ''].includes(text) ? '-' : text),
    } = baseOptions;
    this.align = align;
    this.sort = sort;
    this.sorter = sorter;
    this.ellipsis = ellipsis;
    this.showSorterTooltip = showSorterTooltip;
    this.customRender = customRender;
  }

  id(options = {}) {
    const { key = 'id', label = key, width = '5%', align = 'center', sort = this.sort, defaultSortOrder } = options;
    const { dataIndex, title } = handleKey(key, label);
    const sorter = buildSorter(dataIndex, sort);
    let ret = {
      title,
      dataIndex,
      width,
      align,
      sorter,
      defaultSortOrder, // 仅本地排序时用于设置初始排序
      ellipsis: this.ellipsis,
      showSorterTooltip: this.showSorterTooltip,
      customRender: this.customRender,
    };
    if (sort === SORT_TYPE.SERVER) ret.sortOrder = buildSortOrder(key, sort, this.sorter);
    return ret;
  }

  text(options = {}) {
    const { key, label = key, width, align = this.align, sort = this.sort, defaultSortOrder } = options;
    const { dataIndex, title } = handleKey(key, label);
    const sorter = buildSorter(dataIndex, sort);
    let ret = {
      title,
      dataIndex,
      width,
      align,
      ellipsis: this.ellipsis,
      showSorterTooltip: this.showSorterTooltip,
      customRender: this.customRender,
      sorter,
      defaultSortOrder,
    };
    if (sort === SORT_TYPE.SERVER) ret.sortOrder = buildSortOrder(key, sort, this.sorter);
    return ret;
  }

  number(options = {}) {
    const {
      key,
      label = key,
      scale,
      suffix,
      width,
      align = 'right',
      sort = this.sort,
      defaultSortOrder,
      showZero = false,
      ellipsis = this.ellipsis,
    } = options;
    const { dataIndex, title } = handleKey(key, label);
    const sorter = buildSorter(dataIndex, sort);
    let ret = {
      title,
      dataIndex,
      width,
      align,
      sorter,
      defaultSortOrder,
      ellipsis,
      showSorterTooltip: this.showSorterTooltip,
      customRender: ({ text, record }) => {
        let t = text ? new BigNumber(text).toFormat(scale) : showZero ? '0' : '-';
        let s = suffix ? result(record, getDataIndex(suffix)) : undefined;
        const { te, t: _t } = useLanguage();
        const { ENUMS } = useBootstrap();
        const { Currency } = ENUMS.value;
        return h(
          'div',
          {
            title: s ? `${t} ${Currency?.find(o => o.name === s)?.label ?? s}` : t,
            class: `flex flex-row ${align === 'left' ? 'justify-start' : align === 'right' ? 'justify-end' : 'justify-center'}`,
          },
          [
            h(
              'div',
              {
                style: {
                  height: '22px',
                  lineHeight: '22px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                },
              },
              [t],
            ),
            h(
              'div',
              {
                style: {
                  marginLeft: '4px',
                  width: 'min-content',
                  height: '22px',
                  lineHeight: '22px',
                  fontSize: '10px',
                  fontWeight: 'bold',
                  opacity: '0.6',
                },
              },
              [s ? ` ${Currency?.find(o => o.name === s)?.label ?? s}` : suffix && te(suffix) ? ` ${_t(suffix)}` : ''],
            ),
          ],
        );
      },
    };
    if (sort === SORT_TYPE.SERVER) ret.sortOrder = buildSortOrder(key, sort, this.sorter);
    return ret;
  }

  selection(options = {}) {
    const { key, label = key, name, width, align = this.align, sort = this.sort, defaultSortOrder, showId = true } = options;
    const { dataIndex, title } = handleKey(key, label);
    const sorter = sort ? (sort === SORT_TYPE.SERVER ? true : selectionCompare(name, dataIndex)) : false;
    let ret = {
      title,
      dataIndex,
      width,
      align,
      ellipsis: this.ellipsis,
      showSorterTooltip: this.showSorterTooltip,
      customRender: ({ record }) => {
        const field = name === 'Role' ? 'label' : 'name';
        const selection = useBootstrap()?.[name]?.[result(record, key)];
        let text = selection?.[field];
        if (showId && text) text += ` (${selection?.['value']})`;
        return text ?? '-';
      },
      sorter,
      defaultSortOrder,
    };
    if (sort === SORT_TYPE.SERVER) ret.sortOrder = buildSortOrder(key, sort, this.sorter);
    return ret;
  }

  enum(options = {}) {
    const { key, label = key, name, width, align = this.align, sort = this.sort, defaultSortOrder } = options;
    const { dataIndex, title } = handleKey(key, label);
    const sorter = sort ? (sort === SORT_TYPE.SERVER ? true : enumCompare(name, dataIndex)) : false;
    let ret = {
      title,
      dataIndex,
      width,
      align,
      ellipsis: this.ellipsis,
      showSorterTooltip: this.showSorterTooltip,
      customRender: ({ record }) => {
        const { ENUMS } = useBootstrap();
        const value = result(record, key);
        const item = find(ENUMS.value?.[name], { name: value });
        const color = ENUM_COLOR[name]?.[value];
        return h(
          'span',
          {
            title: item?.label ?? '-',
            style: { color },
          },
          [item?.label ?? '-'],
        );
      },
      sorter,
      defaultSortOrder,
    };
    if (sort === SORT_TYPE.SERVER) ret.sortOrder = buildSortOrder(key, sort, this.sorter);
    return ret;
  }

  fxRateTemplate(options = {}) {
    const PO = 'fxRateTemplatePo';
    const KEY = `${PO}.name`;
    const LABEL = 'fxRateTemplate';
    const { key = KEY, label = LABEL, width, align = this.align, sort = this.sort, defaultSortOrder, showId = true } = options;
    const { dataIndex, title } = handleKey(key, label);
    const sorter = buildSorter(dataIndex, sort);
    let ret = {
      title,
      dataIndex,
      width,
      align,
      ellipsis: this.ellipsis,
      showSorterTooltip: this.showSorterTooltip,
      customRender: ({ record }) => {
        const fxRateTemplatePo = toRaw(record)?.[PO];
        let text = fxRateTemplatePo?.name;
        if (showId && fxRateTemplatePo) text += ` (${fxRateTemplatePo?.['id']})`;
        return text ?? '-';
      },
      sorter,
      defaultSortOrder,
    };
    if (sort === SORT_TYPE.SERVER) ret.sortOrder = buildSortOrder(key, sort, this.sorter);
    return ret;
  }

  shortText(options = {}) {
    const {
      key,
      label = key,
      width,
      leadingDigit = 4,
      starCount = 4,
      align = this.align,
      sort = this.sort,
      defaultSortOrder,
    } = options;
    const { dataIndex, title } = handleKey(key, label);
    const sorter = buildSorter(dataIndex, sort);
    let ret = {
      title,
      dataIndex,
      width,
      align,
      ellipsis: this.ellipsis,
      showSorterTooltip: this.showSorterTooltip,
      customRender: ({ text }) => {
        if (!text) return '-';
        return h(NShortText, {
          value: text,
          leadingDigit,
          starCount,
        });
      },
      sorter,
      defaultSortOrder,
    };
    if (sort === SORT_TYPE.SERVER) ret.sortOrder = buildSortOrder(key, sort, this.sorter);
    return ret;
  }

  shortNumber(options = {}) {
    const {
      key,
      label = key,
      width,
      leadingDigit = 2,
      trailingDigit = 2,
      starCount = 4,
      align = this.align,
      sort = this.sort,
      defaultSortOrder,
    } = options;
    const { dataIndex, title } = handleKey(key, label);
    const sorter = buildSorter(dataIndex, sort);
    let ret = {
      title,
      dataIndex,
      width,
      align,
      ellipsis: this.ellipsis,
      showSorterTooltip: this.showSorterTooltip,
      customRender: ({ text }) => {
        if (!text) return '-';
        return h(NShortNumber, {
          value: text,
          leadingDigit,
          trailingDigit,
          starCount,
        });
      },
      sorter,
      defaultSortOrder,
    };
    if (sort === SORT_TYPE.SERVER) ret.sortOrder = buildSortOrder(key, sort, this.sorter);
    return ret;
  }

  boolean(options = {}) {
    const { key, label = key, width = '6%', align = this.align, sort = this.sort, defaultSortOrder } = options;
    const { dataIndex, title } = handleKey(key, label);
    const sorter = sort ? (sort === SORT_TYPE.SERVER ? true : booleanCompare(dataIndex)) : false;
    let ret = {
      title,
      dataIndex,
      width,
      align,
      ellipsis: this.ellipsis,
      showSorterTooltip: this.showSorterTooltip,
      customRender: ({ record }) => {
        const value = result(record, key);
        const component = value ? CheckCircleFilled : CloseCircleFilled;
        const props = value
          ? { style: 'margin-top: 6px; font-size: 20px; color: rgba(16, 185, 90, 0.8);' }
          : { style: 'margin-top: 6px; font-size: 20px; color: rgba(248, 113, 113, 0.9);' };
        return h(component, props);
      },
      sorter,
      defaultSortOrder,
    };
    if (sort === SORT_TYPE.SERVER) ret.sortOrder = buildSortOrder(key, sort, this.sorter);
    return ret;
  }

  datetime(options = {}) {
    const { key, label = key, width = 165, align = 'center', sort = this.sort, defaultSortOrder } = options;
    const { dataIndex, title } = handleKey(key, label);
    const sorter = buildSorter(dataIndex, sort, true);
    let ret = {
      title,
      dataIndex,
      width,
      align,
      ellipsis: this.ellipsis,
      showSorterTooltip: this.showSorterTooltip,
      customRender: this.customRender,
      sorter,
      defaultSortOrder,
    };
    if (sort === SORT_TYPE.SERVER) ret.sortOrder = buildSortOrder(key, sort, this.sorter);
    return ret;
  }

  bodySlot(options = {}) {
    const {
      key,
      slotKey,
      label = key,
      width,
      align = this.align,
      sort = this.sort,
      defaultSortOrder,
      ellipsis = this.ellipsis,
    } = options;
    const { dataIndex, title } = handleKey(key, label);
    const _slotKey = slotKey ? slotKey : isArray(dataIndex) ? last(dataIndex) : dataIndex;
    const sorter = buildSorter(dataIndex, sort);
    let ret = {
      title,
      key: dataIndex,
      dataIndex,
      width,
      align,
      showSorterTooltip: this.showSorterTooltip,
      customRender: this.customRender,
      ellipsis,
      bodySlot: `body${upperFirst(_slotKey)}`,
      sorter,
      defaultSortOrder,
    };
    if (sort === SORT_TYPE.SERVER) ret.sortOrder = buildSortOrder(key, sort, this.sorter);
    return ret;
  }

  actionSlot(options = {}) {
    const { key = 'action', label = key, width, align = 'center', buttons = 1, ellipsis = false } = options;
    const { dataIndex, title } = handleKey(key, label);
    return {
      title,
      key: dataIndex,
      dataIndex,
      width: width ? width : buttons * 32 + 35,
      align,
      showSorterTooltip: this.showSorterTooltip,
      customRender: this.customRender,
      ellipsis,
      bodySlot: `body${upperFirst(key)}`,
    };
  }
}

const getDataIndex = key => {
  const keys = key.split('.');
  return keys.length > 1 ? keys : keys[0];
};

const handleKey = (key, label) => {
  const { tl } = useLanguage();
  let dataIndex = getDataIndex(key);
  let title = key === label && isArray(dataIndex) ? tl(dataIndex[dataIndex.length - 1]) : tl(label);
  return { dataIndex, title };
};

const buildSorter = (dataIndex, sort, isDate = false) => {
  return sort ? (sort === SORT_TYPE.SERVER ? true : sortCompare(dataIndex, isDate)) : false;
};

const buildSortOrder = (key, sort, sorter) => {
  if (sort === SORT_TYPE.SERVER) {
    const field = sorter?.field;
    const order = sorter?.order;
    const currentField = (Array.isArray(field) ? field?.join('.') : field) ?? undefined;
    return currentField === key ? order : false;
  } else return false;
};
